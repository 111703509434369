import * as React from "react"
import { SEO } from "../components/seo"
// import ScrollAnimation from 'react-animate-on-scroll';
import Layout from '../components/layout';
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";
import { articles } from '../components/articles';

const Press = (props) => {
  return (
    <Layout pathname={props.location.pathname}>
      <section className="h-72 bg-gradient-to-tl from-primary-dark to-primary-light relative after:content-[''] after:block after:bg-mask after:bg-['100%'] after:bg-bottom after:bg-no-repeat after:absolute after:inset-x-0 after:-bottom-1 after:w-full after:h-36 after:z-10">
        <div className="absolute w-full text-center inset-x-0 top-1/2 -translate-y-1/2 z-10 lg:p-20">
        <h1 className="animate__animated animate__fadeInDown animate__slower font-heading text-center text-3xl lg:text-5xl text-white font-bold mb-4">Press</h1>
        </div>
      </section>

      <section className="px-10 lg:px-36 bg-slate-100 py-10">

        <h2 className="font-bold text-2xl mb-8 text-primary text-center">Press Releases</h2>

        <div className="w-[800px] mx-auto max-w-full text-base leading-loose space-y-6 mb-10 pb-10 border-b-2 border-slate-300">

          <h3 className="font-heading font-bold text-2xl"><Link to="/press/kim-nguyen-penaloza-democratic-party-endorsement">Kim Nguyen-Penaloza Dominates Democratic Party Endorsement for CA-45</Link></h3>

          <p>Receiving Near-Unanimous Support From Delegates, Councilwoman Kim Nguyen-Penaloza is now The Presumptive Democratic Party Endorsed Candidate for CA-45</p>

          <Link to="/press/kim-nguyen-penaloza-democratic-party-endorsement" target="_blank" rel="noreferrer" className="font-bold text-[#3D3D3D] bg-gradient-to-r from-secondary via-secondary-light to-secondary text-base lg:text-xl rounded-full inline-block py-2 px-12 text-center transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100">
            Read More
            <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
              <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
            </svg>
          </Link>

        </div>

        <div className="w-[800px] mx-auto max-w-full text-base leading-loose space-y-6">

          <h3 className="font-heading font-bold text-2xl"><Link to="/press/kim-nguyen-penaloza-announces-campaign">Kim Nguyen-Penaloza Announces Campaign for California’s 45th Congressional District</Link></h3>

          <p>Lifelong resident of CA-45 brings local government experience, homegrown roots, and record of bipartisanship to battleground congressional race</p>

          <Link to="/press/kim-nguyen-penaloza-announces-campaign" target="_blank" rel="noreferrer" className="font-bold text-[#3D3D3D] bg-gradient-to-r from-secondary via-secondary-light to-secondary text-base lg:text-xl rounded-full inline-block py-2 px-12 text-center transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100">
            Read More
            <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
              <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
            </svg>
          </Link>

        </div>
      </section>

      <h2 className="font-bold text-2xl my-6 text-primary text-center">In the News</h2>

      {articles.map(article => (
      <section className="container mt-14 mx-auto px-4 lg:px-36 text-gray-700 lg:flex items-start justify-center" key={article.id}>
        <div className="w-full lg:w-1/3">
          <a href={`${article.url}`} target="_blank" rel="noreferrer">
          <img src={`/images/${article.image}`} alt="" className="rounded-md shadow-lg mb-10" />
          </a>
        </div>
        <div className="text-base leading-relaxed w-full lg:w-2/3 lg:pl-10">

          <p className="text-md mb-2 uppercase font-bold leading-snug">{article.source}</p>

          <h3 className="font-black leading-tight text-3xl lg:text-4xl mb-4 text-primary"><a href={`${article.url}`} target="_blank" rel="noreferrer">{article.headline}</a></h3>

          <div className="space-y-4">
            
            <p>{article.preview}</p>
            
            <a href={`${article.url}`} target="_blank" rel="noreferrer" className="font-bold text-[#3D3D3D] bg-gradient-to-r from-secondary via-secondary-light to-secondary text-base lg:text-xl rounded-full inline-block py-2 px-12 text-center transition-all duration-500 bg-size-200 bg-pos-0 hover:bg-pos-100">
            Read More
            <svg xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.616" viewBox="0 0 13.503 23.616" role="img" aria-hidden="true" className="inline-block fill-[#3D3D3D] w-1.5 ml-2 align-middle -mt-1">
              <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M20.679,18,11.742,9.07a1.681,1.681,0,0,1,0-2.384,1.7,1.7,0,0,1,2.391,0L24.258,16.8a1.685,1.685,0,0,1,.049,2.327L14.14,29.32a1.688,1.688,0,0,1-2.391-2.384Z" transform="translate(-11.246 -6.196)"/>
            </svg>
            </a>
            
          </div>
        </div>

      </section>
      ))}
      
    </Layout>
  )
}

export default Press

export const Head = ({location}) => <SEO title="Press" pathname={location.pathname} />
